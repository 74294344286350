import React, { useState, useEffect, useRef } from "react";
import "./App.css";
import qpcore from "./qpcore.png";
import tgIcon from "./tg.png";
import ytIcon from "./YT.png";
import xIcon from "./x.png";
import igIcon from "./IG.png";
import ttIcon from "./tt.png";
import backIcon from './back_icn.png';

const TermsConditionsContent = () => {
  const contentRef = useRef(null);

  useEffect(() => {
    const content = contentRef.current;
    let scrollStep = 1;
    let scrollInterval;

    const startAutoScroll = () => {
      scrollInterval = setInterval(() => {
        if (content.scrollTop + content.clientHeight >= content.scrollHeight) {
          clearInterval(scrollInterval);
        } else {
          content.scrollTop += scrollStep;
        }
      }, 50);
    };

    startAutoScroll();

    const stopAutoScroll = () => {
      clearInterval(scrollInterval);
      content.removeEventListener('wheel', stopAutoScroll);
      content.removeEventListener('touchstart', stopAutoScroll);
    };

    content.addEventListener('wheel', stopAutoScroll);
    content.addEventListener('touchstart', stopAutoScroll);

    return () => {
      content.removeEventListener('wheel', stopAutoScroll);
      content.removeEventListener('touchstart', stopAutoScroll);
      clearInterval(scrollInterval);
    };
  }, []);

  return (
    <div className="legal-content-container">
      <div className="legal-content" ref={contentRef}>
        <h1>Terms & Conditions</h1>
        <p><strong>Effective Date:</strong> September 30, 2024</p>
        <h2>Introduction</h2>
        <p>
          Welcome to qpCore Platform ("we," "us," or "our"). By accessing or using our application, you agree to these Terms & Conditions ("Terms"). Please read them carefully.
        </p>
        <h2>Use of the Application</h2>
        <p>Our platform includes:</p>
        <ol>
          <li>
            <strong>qpCore Artist Mini-Game:</strong> A Telegram-based game where users tap coins to earn rewards within the qpCore ecosystem.
          </li>
          <li>
            <strong>Human Prototype Model:</strong> A virtual assistant leading our content releases and aiding in AI integration.
          </li>
          <li>
            <strong>Next-Generation Music Label:</strong> Helping artists connect with the digital world and set new trends.
          </li>
        </ol>
        <h2>User Responsibilities</h2>
        <p>
          <strong>Compliance:</strong> You agree to use the application in accordance with all applicable laws and regulations.
        </p>
        <p>
          <strong>Data Security:</strong> You are responsible for maintaining the confidentiality of your data information.
        </p>
        <h2>Intellectual Property</h2>
        <p>
          All content and materials are owned by qpCore or its licensors and are protected by intellectual property laws. Unauthorized use is prohibited.
        </p>
        <h2>Limitation of Liability</h2>
        <p>
          qpCore is not liable for any indirect, incidental, or consequential damages arising from your use of the application.
        </p>
        <h2>Changes to Terms</h2>
        <p>
          We may update these Terms periodically. Changes will be effective upon posting to this page.
        </p>
        <h2>Contact Us</h2>
        <p>
          If you have any questions, please contact us at contact@qpcore.com.
        </p>
      </div>
    </div>
  );
};

const PrivacyPolicyContent = () => {
  const contentRef = useRef(null);

  useEffect(() => {
    const content = contentRef.current;
    let scrollStep = 1;
    let scrollInterval;

    const startAutoScroll = () => {
      scrollInterval = setInterval(() => {
        if (content.scrollTop + content.clientHeight >= content.scrollHeight) {
          clearInterval(scrollInterval);
        } else {
          content.scrollTop += scrollStep;
        }
      }, 50);
    };

    startAutoScroll();

    const stopAutoScroll = () => {
      clearInterval(scrollInterval);
      content.removeEventListener('wheel', stopAutoScroll);
      content.removeEventListener('touchstart', stopAutoScroll);
    };

    content.addEventListener('wheel', stopAutoScroll);
    content.addEventListener('touchstart', stopAutoScroll);

    return () => {
      content.removeEventListener('wheel', stopAutoScroll);
      content.removeEventListener('touchstart', stopAutoScroll);
      clearInterval(scrollInterval);
    };
  }, []);

  return (
    <div className="legal-content-container">
      <div className="legal-content" ref={contentRef}>
        <h1>Privacy Policy</h1>
        <p><strong>Effective Date:</strong> September 30, 2024</p>
        <h2>Introduction</h2>
        <p>
          At qpCore, we respect your privacy. This Privacy Policy explains how we collect, use, and protect your personal information when you use our application.
        </p>
        <h2>Information We Collect</h2>
        <ul>
          <li>
            <strong>Personal Information:</strong> Such as your name and contact details when you create an account (Will be provided in November 2024).
          </li>
          <li>
            <strong>Usage Data:</strong> Information about how you interact with our application.
          </li>
        </ul>
        <h2>How We Use Your Information</h2>
        <p>
          <strong>To Provide Services:</strong> Operating and maintaining the application.
        </p>
        <p>
          <strong>To Improve Services:</strong> Analyzing usage to enhance user experience.
        </p>
        <p>
          <strong>Communication:</strong> Sending updates and promotional materials.
        </p>
        <h2>Information Sharing</h2>
        <p>
          We do not sell or rent your personal information to third parties. We may share information with service providers who assist us in operating the application.
        </p>
        <h2>Data Security</h2>
        <p>
          We implement reasonable security measures to protect your information from unauthorized access.
        </p>
        <h2>Children's Privacy</h2>
        <p>
          Our application is not intended for users under 13 years of age. We do not knowingly collect personal information from children.
        </p>
        <h2>Changes to This Policy</h2>
        <p>
          We may update this Privacy Policy from time to time. Changes will be posted on this page with an updated effective date.
        </p>
        <h2>Contact Us</h2>
        <p>
          For questions or concerns about this Privacy Policy, please contact us at contact@qpcore.com.
        </p>
      </div>
    </div>
  );
}

function App() {
  const [currentStep, setCurrentStep] = useState(0);

  const sendEvent = async (eventName) => {
    try {
      const response = await fetch('/api/track-event', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          eventName: eventName
        })
      });

      const data = await response.json();
      if (data.success) {
        console.log(`Подія "${eventName}" успішно відправлено.`);
      } else {
        console.error(`Помилка при відправці події "${eventName}":`, data.error);
      }
    } catch (error) {
      console.error(`Помилка при відправці події "${eventName}":`, error);
    }
  };

  const handleLinkClick = (event, eventName, url) => {
    event.preventDefault();

    const newWindow = window.open(url, '_blank', 'noopener,noreferrer');

    sendEvent(eventName).then(() => {
      if (newWindow) {
        newWindow.focus();
      }
    }).catch(() => {
      if (newWindow) {
        newWindow.focus();
      }
    });
  };

  const steps = [
    {
      id: 'welcome',
      title: "Welcome to",
      subtitle: "qpCore Platform",
      description: "Social Media Links",
      content: (
        <div className="button-container">
          <a
            href="https://youtu.be/lf9qpbIMID4"
            className="button"
            onClick={(e) => handleLinkClick(e, 'YouTube Button', 'https://youtu.be/lf9qpbIMID4')}
          >
            <div className="button-content">
              <div className="button-icon-container">
                <img src={ytIcon} alt="YouTube" className="button-icon" />
              </div>
              <span className="button-text">YouTube Episode 0</span>
            </div>
          </a>
          <a
            href="https://www.instagram.com/qp_core/"
            className="button"
            onClick={(e) => handleLinkClick(e, 'Instagram Button', 'https://www.instagram.com/qp_core/')}
          >
            <div className="button-content">
              <div className="button-icon-container">
                <img src={igIcon} alt="Instagram" className="button-icon" />
              </div>
              <span className="button-text">Instagram Profile</span>
            </div>
          </a>
          <a
            href="https://t.me/qpcore_bot/game/"
            className="button"
            onClick={(e) => handleLinkClick(e, 'Telegram Button', 'https://t.me/qpcore_bot/game/')}
          >
            <div className="button-content">
              <div className="button-icon-container">
                <img src={tgIcon} alt="Telegram" className="button-icon" />
              </div>
              <span className="button-text">Telegram Game</span>
            </div>
          </a>
          <a
            href="https://x.com/qp_core"
            className="button"
            onClick={(e) => handleLinkClick(e, 'X Button', 'https://x.com/qp_core')}
          >
            <div className="button-content">
              <div className="button-icon-container">
                <img src={xIcon} alt="X" className="button-icon" />
              </div>
              <span className="button-text">X Profile</span>
            </div>
          </a>
          <a
            href="https://www.tiktok.com/@qp_core"
            className="button"
            onClick={(e) => handleLinkClick(e, 'TikTok Button', 'https://www.tiktok.com/@qp_core')}
          >
            <div className="button-content">
              <div className="button-icon-container">
                <img src={ttIcon} alt="TikTok" className="button-icon" />
              </div>
              <span className="button-text">TikTok Profile</span>
            </div>
          </a>
        </div>
      )
    },
    {
      id: 'privacy-policy',
      title: "",
      subtitle: "",
      description: "",
      content: <PrivacyPolicyContent />
    },
    {
      id: 'terms-conditions',
      title: "",
      subtitle: "",
      description: "",
      content: <TermsConditionsContent />
    }	  
  ];

  const handleFooterLinkClick = (stepIndex) => {
    setCurrentStep(stepIndex);
  };

  const handleBackClick = () => {
    if (currentStep !== 0) {
      setCurrentStep(0);
    }
  };

  const renderSteps = () => {
    const step = steps[currentStep];

    return (
      <div
        key={currentStep}
        className={`content visible`}
        id={`step-${currentStep}`}
      >
        {currentStep === 0 && (
          <div className="coin-container">
            <img src={qpcore} alt="qp Core" className="coin" />
          </div>
        )}
        {step.title && <h1 className="slide-down">{step.title}</h1>}
        {step.subtitle && <h2 className="slide-down">{step.subtitle}</h2>}
        {step.description && <p className="slide-up">{step.description}</p>}
        {step.content}
      </div>
    );
  };

  return (
    <div className="App">
      <header className="App-header">
        {renderSteps()}
        {currentStep !== 0 && (
          <img
            src={backIcon}
            alt="Back"
            className="back-icon visible"
            onClick={handleBackClick}
          />
        )}
      </header>
      <footer className="footer">
        <div className="footer-content slide-up">
          <button
            className="footer-link"
            onClick={() => handleFooterLinkClick(1)}
          >
            Privacy Policy
          </button>
          <span className="footer-divider">|</span>
          <button
            className="footer-link"
            onClick={() => handleFooterLinkClick(2)}
          >
            Terms & Conditions
          </button>
          <span className="footer-divider">|</span>
          <span className="footer-copy">© qpCore 2024</span>
        </div>
      </footer>
    </div>
  );
}

export default App;

